<template>
  <div>
    <el-upload
        class="carousel"
        :action="uploadUrl"
        list-type="picture-card"
        :file-list="fileList"
        :on-preview="handlePictureCardPreview"
        :on-success="handleSuccess"
        :on-remove="handleRemove">
    </el-upload>

    <el-dialog :visible.sync="dialogVisible">
      <div style="display: flex;justify-content: space-evenly">
        <el-input v-model="file.sort" placeholder="请输入排序位置" style="width: 500px"></el-input>
        <el-button type="primary" @click="submit">保存</el-button>
      </div>

    </el-dialog>
  </div>
</template>

<script>
import {addModifyBanner, delBanner, pageBanner} from '@/api/sys';

export default {
  name: "admin.vue",
  data() {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      uploadUrl: process.env.VUE_APP_BASE_URL + '/file/oss',
      fileList: [],
      file: {},
    }
  },
  created() {

    this.pageBanner();
  },
  methods: {
    async pageBanner() {
      const {data, code, msg} = await pageBanner();
      if (code != 200) return this.fileList = [];
      this.fileList = data;
    },
    async handleRemove(file) {

      const {data, code, msg} = await delBanner([file.id]);

      if (code != 200) return this.$message.error(msg);

      this.$message.success('删除成功!');

    },
    async submit() {
      const {data, code, msg} = await addModifyBanner(this.file);
      if (code != 200) return this.$message.error(msg);
      this.$message.success(msg);
      this.dialogVisible = false;
      await this.pageBanner();

    },

    async handleSuccess(file) {
      if (file.code != 200) return this.$message.error('上传失败');
      const {data, code, msg} = await addModifyBanner({...{id: null}, ...file.data});
      if (code != 200) return this.$message.error(msg);
      this.$message.success('添加成功');
      await this.pageBanner();
    },
    handlePictureCardPreview(file) {
      this.file = file;
      this.dialogVisible = true;
    }
  }
}
</script>

<style scoped>

.carousel /deep/ .el-upload-list__item {
  width: 100%;
  height: 600px;
}


</style>
